let key = {};
if (process.env.REACT_APP_MODE === "live") {
  console.log("Set live Config");
  const API_URL = "https://api.whitecrypto.in";
  console.log = function () {};
  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LdVcXogAAAAAEHkWkD_hcoyXoIxg5_4GpEiGFvF", //local
    API_URL: "https://api.whitecrypto.in",
    FRONT_URL: "https://whitecrypto.in",
    ADMIN_URL: "https://whitecrypto.in/admin",
    SOCKET_URL: "https://api.whitecrypto.in",
    getGeoInfo: "https://ipapi.co/json/",
    zaakpayurl: "https://zaakstaging.zaakpay.com/api/paymentTransact/V8",
    MerchantID: "fb2016ffd3a64b2994a6289dc2b671a4",
    returnUrl: "https://api.whitecrypto.in/api/zaakpay/zaakpayconfirmed",
    zaaksecretkey: "0678056d96914a8583fb518caf42828a",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
  };
} else if (process.env.REACT_APP_MODE === "production") {
  console.log("Set Production Config");
  const API_URL = "https://moneyfarmapi.wealwin.com";
  //   console.log = function () {};
  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LeNWEwlAAAAAPqMyLFiQAhtXvV0gf7JrfudMwoI", //local
    API_URL: "https://whitecryptoapi.wealwin.com",
    FRONT_URL: "https://whitecrypto-frontend.pages.dev",
    ADMIN_URL: "https://whitecrypto-controls.pages.dev",
    SOCKET_URL: "https://whitecryptoapi.wealwin.com",
    getGeoInfo: "https://ipapi.co/json/",
    zaakpayurl: "https://zaakstaging.zaakpay.com/api/paymentTransact/V8",
    MerchantID: "fb2016ffd3a64b2994a6289dc2b671a4",
    returnUrl: "https://whitecryptoapi.wealwin.com/api/zaakpay/zaakpayconfirmed",
    zaaksecretkey: "0678056d96914a8583fb518caf42828a",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
  };
}  else if (process.env.REACT_APP_MODE === "stage") {
  console.log("Set Production Config");
  const API_URL = "https://stagingapi.whitecrypto.in";
  //   console.log = function () {};
  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LeNWEwlAAAAAPqMyLFiQAhtXvV0gf7JrfudMwoI", //local
    API_URL: "https://stagingapi.whitecrypto.in",
    FRONT_URL: "https://staging.whitecrypto.in",
    ADMIN_URL: "https://stagingcontrols.whitecrypto.in",
    SOCKET_URL: "https://stagingapi.whitecrypto.in",
    getGeoInfo: "https://ipapi.co/json/",
    zaakpayurl: "https://zaakstaging.zaakpay.com/api/paymentTransact/V8",
    MerchantID: "fb2016ffd3a64b2994a6289dc2b671a4",
    returnUrl: "https://stagingapi.whitecrypto.in/api/zaakpay/zaakpayconfirmed",
    zaaksecretkey: "0678056d96914a8583fb518caf42828a",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
  };
}else {
  console.log("Set Development Config");
  const API_URL = "http://localhost"; //http://192.168.0.111  http://localhost
  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LeNWEwlAAAAAPqMyLFiQAhtXvV0gf7JrfudMwoI", //local
    API_URL: `${API_URL}:2053`,
    SOCKET_URL: `${API_URL}:2053`,
    getGeoInfo: "https://ipapi.co/json/",
    zaakpayurl: "https://zaakstaging.zaakpay.com/api/paymentTransact/V8",
    MerchantID: "fb2016ffd3a64b2994a6289dc2b671a4",
    returnUrl: "http://localhost:2053/api/zaakpay/zaakpayconfirmed",
    zaaksecretkey: "0678056d96914a8583fb518caf42828a",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
  };
}

export default {
  ...key,
  ...{ SITE_DETAIL: require("./siteConfig") },
};
